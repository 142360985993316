import React from "react";
import {useStaticQuery, graphql, Link} from "gatsby";
import BaseLayout from "../../../components/layout"
import Seo from "../../../components/seo";
import BookPageHeader from "../../../components/book-page-header";
import TextBlock from "../../../components/text-block";
import HorizontalRule from "../../../components/horizontal-rule";
import BookTOCCard from "../../../components/book-toc-card";
import ChapterSummary from "../../../components/chapter-summary";
import BreadcrumbTrail from "../../../components/breadcrumb-trail";
import kebabCase from "lodash/kebabCase";


export default function WritingFictionPage(props) {
  let data = useStaticQuery(query)
  const wallyBook = data.wallyCollection.nodes[0];
  const wallyChapters = data.allMdx.nodes;

  const sellBookText = [
    'Wally <i>and the Holiday Jailbreak</i> is a holiday comedy that was published on December 12th, 2022.',
    `The first five chapters are available here on my website for free. When you\'re ready for the full book, it is sold <a className="text-link" href=${wallyBook.purchase.url} target="_blank" rel="noopener noreferrer">on Amazon</a> in eBook, paperback, and hardcover formats. The title is also free with Kindle Unlimited.`,
    'The audiobook has been published through several dozen distributors (including Audible), but is still in quality control and not yet available for purchase. I will send out a message when the audiobook can be purchased, which should be any day.',
    "If you'd like to stay up-to-date with Wally and my other writing, you can sign up for my newsletter and reader group, <i>The Storied Scrolls</i>."
  ]

  let breadcrumbTitle = wallyBook.title
  if (wallyBook.subtitle) {
    breadcrumbTitle = wallyBook.title + ' ' + wallyBook.subtitle
  }

  let breadcrumbList = [
    [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: 'Library',
        url: '/writing/library/'
      },
      {
        title: breadcrumbTitle,
      }
    ]
  ]

  return (
    <BaseLayout>
      <Seo title={`${wallyBook.title}`} desc={wallyBook.seo_description} pathname={props.location.pathname} breadcrumbList={breadcrumbList} banner={wallyBook.seo_image}></Seo>

      <BookPageHeader book={wallyBook} chapters={wallyChapters} breadcrumbTrail={breadcrumbList[0]}></BookPageHeader>

      <div className="layout-base-w app-px-base">
        <div className="mt-40">
          <h3 className="text-header-2 text-mint text-center mb-12">{wallyBook.title}{wallyBook.subtitle && <> {wallyBook.subtitle}</>}</h3>

          <div className="layout-reading-w mt-12 mb-32">
            <TextBlock text={wallyBook.summary}></TextBlock>
          </div>

          <div className="my-40 text-mint">
            <HorizontalRule ruleType="2"></HorizontalRule>
          </div>

          <div className={`base-w mt-16 mb-40 mx-auto flex gap-10 flex-col md:flex-row`}>
            <div className="mx-auto mb-4 md:mb-0 w-full sm:[width:275px] sm:[flex-basis:275px]">
              <img className="w-full md:[margin-top:11px]" src={wallyBook.book_cover.portrait} alt={`${wallyBook.title} Book Cover`}/>
            </div>
            <div className="shrink grow flex-1">
              <TextBlock text={sellBookText} textStyle="italic" textSize="large"></TextBlock>
              <div className="flex gap-8">
                <a href="/newsletter/the-storied-scrolls/">
                  <button className="btn btn-medium btn-white mt-12">Newsletter →</button>
                </a>
                <a href={wallyBook.purchase.url} target="_blank" rel="noopener noreferrer">
                  <button className="btn btn-medium btn-gold mt-12">{wallyBook.purchase.button_text} →</button>
                </a>
              </div>
            </div>
          </div>

          {(wallyBook.is_published && wallyChapters.length > 0) &&
            <>
              <div className="my-40 text-mint">
                <HorizontalRule ruleType="4"></HorizontalRule>
              </div>

              <div className="my-40">
                <BookTOCCard chapters={wallyChapters}></BookTOCCard>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-40 my-40">
                {wallyChapters.map((chapter, index) => (
                  <ChapterSummary key={index} chapter={chapter}></ChapterSummary>
                ))}
              </div>

              <div className="my-40 text-mint">
                <HorizontalRule ruleType="2"></HorizontalRule>
              </div>

              <div className="flex justify-center my-40">
                <ChapterSummary chapter={wallyChapters[0]} summaryType="cta"></ChapterSummary>
              </div>
            </>
          }

        </div>
      </div>
    </BaseLayout>
  )
}

export const query = graphql`
  query WritingWally {
    allMdx(
      sort: { fields: [frontmatter___chapter], order: ASC }
      filter: { frontmatter: { published: { eq: true }, collection: { eq: "Wally and the Holiday Jailbreak" } } }
    ) {
      nodes {
        ...ArticleSnippet
      }
    }
    
    wallyCollection: allCollections(
      filter: { title: { eq: "Wally and the Holiday Jailbreak" } }
      limit: 1
    ) {
      nodes {
        ...CollectionFields
      }
    }
  }
`;
